<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.kname" @change="Search" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model.trim="search.product_model_code" @change="Search" placeholder="产品型号编号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model.trim="search.version_code" @change="Search" placeholder="版本"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="updateStatus({code:'all',product_model_code:''},1,'分配')">全部分配</el-button>
        <el-button type="danger" size="small" @click="updateStatus({code:'all',product_model_code:''},2,'取消')">全部取消</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="所属产品型号">
        <template v-slot="scope">
          <span>{{ scope.row.product_model_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="包名称">
        <template v-slot="scope">
          <span>{{ scope.row.package_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="版本号">
        <template v-slot="scope">
          <span>{{ scope.row.version_code }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px">
        <template v-slot="scope">
          <el-button type="primary" v-if="!scope.row.hasDis" size="small" @click="distribute(scope.row)">分&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;配</el-button>
          <el-button type="danger" v-else size="small" @click="cancel(scope.row)">取消分配</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: '',
        product_model_code: '',
        version_code: '',
      },
      list: [],
      total: 0,
      solidCodeList: [],
      softCode: ''
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    GetInfo () {
      this.api.SoftDetail({
        code: this.softCode
      }).then(res => {
        if (res.data.code == 200) {
          this.solidCodeList = JSON.parse(res.data.data.firmware_version_ids)
          this.Search()
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Search() {
      this.list = []
      this.api.FirmwareList(this.search).then(res => {
        if (res.data.code == 200) {
          if (this.solidCodeList.length !== 0) {
            let brr = []
            let temp = {}
            res.data.data.data.forEach(item => {
              for(let i=0;i<this.solidCodeList.length;i++){
                if (this.solidCodeList[i] == item.code) {
                  temp = item
                  temp.hasDis = true
                  brr.push(temp)
                  return
                } else if(i == this.solidCodeList.length-1) {
                  temp = item
                  temp.hasDis = false
                  brr.push(temp)
                }
              }
            })
            this.list = brr
          } else {
            this.list = res.data.data.data
          }
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    updateStatus(item,status,type) {
      this.api.FirmwareDistribute({
        code: this.softCode,
        firmware_code: item.code,
        product_model_code: item.product_model_code,
        status: status
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success(type+'成功')
          this.GetInfo()
          return
        }
        this.$message.error(type+'失败' + res.data.msg)
      })
    },
    distribute (item) {
      this.updateStatus(item,1,'分配')
    },
    cancel (item) {
      this.updateStatus(item,2,'取消')
    },
    goback () {
      this.$router.push('/iot/version/soft')
    }
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () {
    this.softCode = this.$route.params.code
    this.GetInfo()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
